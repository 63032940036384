/**
 * 针对给出的请求实例进行本地化配置
 */
import axios from 'axios'
import {
  MessageBox,
  Message
} from 'element-ui'
import {
  getToken
} from '@/utils/auth'
import cache from '@/plugins/cache'
import errorCode from '@/utils/errorCode'
import {
  tansParams
} from '@/utils/validate'
import eventBus from '@/utils/event-bus'
// import store from '@/store'
// import Cookies from 'js-cookie'
// import CONFIGS from '../config'
// import Vue from 'Vue'
// import Vuex from 'Vuex'
// const app = Vue.prototype

// const isServer = !!process.server
// const isClient = !!process.browser
let isReloginShow

const service = axios.create( {
  timeout : 50000
} )

// request拦截器
service.interceptors.request.use( async config => {
  if ( !config.url.startsWith( 'http://' ) && !config.url.startsWith( 'https://' ) ) {
    if ( config.url.startsWith( '/' ) ) {
      // 处理内部请求
      if ( process.browser ) {
        config.url = process.env.BASE_URL + config.url
      } else {
        config.url = process.env.BACK_URL + config.url
      }
    }
  }

  // 是否需要设置 token
  const isToken = ( config.headers || {} ).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = ( config.headers || {} ).repeatSubmit === false
  if ( !isToken ) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if ( config.method === 'get' && config.params ) {
    let url = config.url + '?' + tansParams( config.params )
    url = url.slice( 0, -1 )
    config.params = {}
    config.url = url
  }
  if ( !isRepeatSubmit && ( config.method === 'post' || config.method === 'put' ) ) {
    // let flag
    // if ( config.url == '/file/upload/file' ||
    //   config.url == '/file/upload/image' ||
    //   config.url == '/api/v1/common/uploadImg' ||
    //   config.url == '/api/v1/common/uploadFile' ) {
    //   flag = true
    // } else {
    //   flag = false
    // }
    const requestObj = {
      url : config.url,
      data : typeof config.data === 'object' ? JSON.stringify( config.data ) : config.data,
      time : new Date().getTime()
    }
    const sessionObj = cache.session.getJSON( 'sessionObj' )
    if ( sessionObj === undefined || sessionObj === null || sessionObj === '' ) {
      cache.session.setJSON( 'sessionObj', requestObj )
    } else {
      // const s_url = sessionObj.url // 请求地址
      // const s_data = sessionObj.data // 请求数据
      // const s_time = sessionObj.time // 请求时间
      // const interval = 100 // 间隔时间(ms)，小于此时间视为重复提交
      // if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url && !flag) {
      //   const message = '正在处理，请勿频繁操作！'
      //   console.warn(`[${s_url}]: ` + message)
      //   return Promise.reject(new Error(message))
      // } else {
      //   cache.session.setJSON('sessionObj', requestObj)
      // }
      cache.session.setJSON( 'sessionObj', requestObj )
    }
  }
  return config
}, error => {
  console.log( error )
  Promise.reject( error )
} )

// 响应拦截器
service.interceptors.response.use( res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if ( res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer' ) {
    return res.data
  }
  if ( code === 401 ) {
    if ( !isReloginShow ) {
      isReloginShow = true
      MessageBox.confirm( '登录已过期，请重新登录', '系统提示', {
        confirmButtonText : '登录',
        cancelButtonText : '取消',
        type : 'warning'
      } ).then( () => {
        isReloginShow = false
        // Cookies.set('activeIndex', '1')
        $nuxt.$store.dispatch( 'FedLogOut' ).then( () => {
          eventBus.$emit( 'loginOrRegister', 'openLogin' )
        } )
      } ).catch( () => {
        isReloginShow = false
        $nuxt.$store.dispatch( 'FedLogOut' ).then( () => {
          location.href = '/'
        } )
      } )
    }
    return Promise.reject( '无效的会话，或者会话已过期，请重新登录。' )
  } else if ( code === 500 ) {
    Message( {
      message : msg,
      type : 'error'
    } )
    return Promise.reject( new Error( msg ) )
  } else if ( code !== 200 ) {
    Notification.error( {
      title : msg
    } )
    return Promise.reject( 'error' )
  } else {
    return res.data
  }
},
error => {
  console.log( 'err' + error )
  let { message } = error
  if ( message === 'Network Error' ) {
    message = '后端接口连接异常'
  } else if ( message.includes( 'timeout' ) ) {
    message = '系统接口请求超时'
  } else if ( message.includes( 'Request failed with status code' ) ) {
    message = '系统接口' + message.substr( message.length - 3 ) + '异常'
  }
  Message( {
    message : message,
    type : 'error',
    duration : 5 * 1000
  } )
  return Promise.reject( error )
}
)
export const get = ( url, params ) => {
  return new Promise( ( resolve, reject ) => {
    axios
      .get( url, {
        params : params
      } )
      .then( res => {
        resolve( res.data )
      } )
      .catch( function( error ) {
        if ( error.response ) {
          reject( error.response.data )
        }
      } )
  } )
}

export default service //
